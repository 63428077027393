import React, {
    // Component,
    Dispatch,
    FC,
    SetStateAction,
    useState,
    useRef,
    useEffect,
    useCallback,
    // useCallback,
  } from "react";
  import { Modal, Fade, CircularProgress } from "@mui/material";
  import "tinymce/skins/ui/oxide/skin.min.css";
  import whiteCancel from "../common/img/white-cancel.svg";  
import { useGetAcknowledgeListQuery } from "../../app/services/notice-board";
  interface IModalAnnounEditor {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    id:number;
  }
  
  // interface IFilterStates {
  //   tag: string;
  // }
  const NoticeBoardAcknowledgePopup: FC<IModalAnnounEditor> = ({
    isOpen,
    setIsOpen,
    id
  }) => {  
    const [employeeList,setEmployeeList]=useState<any>([]);
    const {data:ackList,isLoading,isFetching}=useGetAcknowledgeListQuery(id);
    useEffect(()=>{
      if(isFetching){
        setEmployeeList([])
      }
        if(ackList){
          setEmployeeList(ackList?.noticeBoardList)
        }
        
    },[ackList])
    useEffect(()=>{
      setEmployeeList([])
    },[isOpen])
    return (
      <Modal
        open={isOpen}
        style={{
          background: "rgb(0 0 0 / 0.3)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setIsOpen(false);
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus={true}
      >
        <Fade in={isOpen} timeout={500}>
          <div className="modalContent w-full xl:max-w-[1200px] max-w-[90%] annoucement-modalw-full px-5">
            <div className="">
              <div
                id="modal-modal-title"
                className="bg-vorpblue relative flex justify-between py-6 sm:px-10 px-5 rounded-t-xl"
              >
                <h3 className="text-18 font-primary font-semibold text-white">
                  Notice Board Acknowledge List
                </h3>
                <button
                  className="flex items-center justify-center"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <img src={whiteCancel} alt="close modal icon" />
                </button>
              </div>
              <div
                id="modal-modal-description"
                className="pb-9 px-5 bg-white rounded-b-xl  overflow-y-auto max-h-[70vh] h-full min-h-[200px]"
              >                
                {isLoading==true?<div className="flex items-center justify-center min-h-[200px]">
                <CircularProgress/>
                </div>:
                <div className="pt-5">
                  <ul className="grid grid-cols-3 gap-3 max-h-[50vh] overflow-auto custom_scrollbar_popup ">
                    {employeeList?.map((emp:any,key:number)=>{
                      return <li key={key} className="bg-grayLight px-3 py-2 rounded-lg mb-1 w-full">
                        {emp.name_en}
                      </li>
                    })}
                  </ul>
                </div>
                }
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  };
  
  export default NoticeBoardAcknowledgePopup;
  