import React, { useEffect, useMemo, useState } from 'react';
import dashboard from './img/ic_round-space-dashboard.svg';
import dashboard_active from './img/ic_round-space-dashboard-active.svg';
import profile from './img/profile-icon.svg';
import profile_active from './img/profile-icon-active.svg';
import employee from './img/employee-icon.svg';
import employee_active from './img/employee-icon-active.svg';
import pwd from './img/pwd-icon.svg';
import pwd_active from './img/pwd-icon-active.svg';
import performance from './img/performance-icon.svg';
import performance_active from './img/performance-icon-active.svg';
//import recruitment from "./img/recruitment-icon.svg";
import recruitment_active from './img/recruitment-icon-active.svg';
import doc from './img/doc-icon.svg';
import doc_active from './img/doc-icon.svg';
import announcement from './img/announcement-icon.svg';
import claims from './img/claims_icon.svg';
import claims_active from './img/claims_active.svg';
import claimForm from './img/claim_form.svg';
import claimFormActive from './img/claim_form_active.svg';
import languageActive from './img/language-active.svg';
import language from './img/language.svg';
import announcement_active from './img/announcement-icon-active.svg';
import media from './img/media.svg';
import media_active from './img/media-active.svg';
import bkuser from './img/bk-user.svg';
import bkuser_active from './img/bk-user-active.svg';
import project from './img/project-icon.svg';
import project_active from './img/project-icon-active.svg';
import checker_project from './img/checker_project.svg';
import checker_project_active from './img/checker_project_active.svg';
import crm from './img/crm-icon.svg';
import crm_active from './img/crm-icon-active.svg';
import activityLog from './img/activityLog.svg';
import activityLog_active from './img/activityLog-active.svg';
import { courseRootPath, instructorDashboardPath } from '../../lms/urls';
const SidebarFunc = () => {
  var userDatas = localStorage.getItem('userData');
  var userJson: any = {};
  if (userDatas) {
    userJson = JSON.parse(userDatas);
    userJson.token = userJson.accessToken;
  }

  var getUser = userJson;
  const getAuthPermis: string[] = useMemo(() => [], []);

  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }
  // const checkPermis = getAuthPermis.includes(permissions[0])

  const [docCheck, setDocCheck] = useState(false);
  const [claimTypeCheck, setClaimTypeCheck] = useState(false);
  const [announcCheck, setAnnouncCheck] = useState(false);
  const [noticeBoardCheck, setNoticeBoardCheck] = useState(false);
  const [profileCheck, setProfilleCheck] = useState(false);
  const [dashboardCheck, setDashboardCheck] = useState(false);
  const [pwdCheck, setPwdCheck] = useState(false);
  const [permisCheck, setPermisCheck] = useState(false);
  const [mediaCheck, seMediaCheck] = useState(false);
  const [employeeeCheck, setEmployeeeCheck] = useState(false);
  const [leaveCheck, setLeaveCheck] = useState(false);
  const [leaveTypeCheck, setLeaveTypeCheck] = useState(false);
  const [ActiveTimeCheck, setActiveTimeCheck] = useState(false);
  const [roleCheck, setRoleCheck] = useState(false);
  const [userCheck, setUserCheck] = useState(false);
  const [projectCheck, setProjectCheck] = useState(false);
  const [activityLogCheck, setActivityLogCheck] = useState(false);
  const [claimsChecked, setClaimsCheck] = useState(false);
  const [perfomance, setPerformance] = useState(false);
  const [recruitment, setRecruitment] = useState(false);
  const [viewCrm, setViewCRM] = useState(false);
  const [otList, setOTList] = useState(false);
  const [offDayWorking, setOffDayWorking] = useState(false);
  const [taskCategory, setTaskCategory] = useState(false);
  const [department, setDepartment] = useState(false);
  const [designation, setDesignation] = useState(false);
  const [category,setCategory] = useState(false);
  const [faq,setFAQ] = useState(false);
  const [manual,setManual] = useState(false);
  const [reminder,setReminder] = useState(false);
  const [usefulLinks,setUsefulLink] = useState(false);
  const [cms,setCMS] = useState(false);
  const [checkIncentive, setCheckIncentive] = useState(false);
  const [checkExchangeRate, setCheckExchangeRage] = useState(false);
  const [payroll, setPayroll] = useState(false);
  const [viewProgressChecker, setViewProgressChecker] =
    useState<boolean>(false);
  const [viewProgressCheckerAcc, setViewProgressCheckerAcc] =
    useState<boolean>(false);

  useEffect(() => {
    const payRollView = getAuthPermis.filter((f) => f == 'Manage_Payroll');

    const filterActiveTime = getAuthPermis.filter(
      (f) => f == 'View_Active_Time_Report'
    );
    const filterDashboard = getAuthPermis.filter(
      (f) => f == 'View_Admin_Dashboard'
    );
    const checkOffDay = getAuthPermis.filter(
      (f) => f == 'Manage_Office_Working_Days'
    );

    const filterAnnounc = getAuthPermis.filter((f) => f == 'View_Announcement');
    const filterNoticeBoard= getAuthPermis.filter((f) => f == 'View_NotiBoard');
    const otList = getAuthPermis.filter((f) => f == 'View_All_OT_List');
    const otOwnList = getAuthPermis.filter((f) => f == 'View_Own_OT_List');

    const claimType = getAuthPermis.filter((f) => f == 'Manage_ClaimForms');
    const claimsCheck = getAuthPermis.filter((f) => f == 'Manage_ClaimForms');
    const approveCheck = getAuthPermis.filter((f) => f == 'Approve_ClaimForms');

    const filterDoc = getAuthPermis.filter((f) => f == 'View_Document');
    const filterOwnDoc = getAuthPermis.filter((f) => f == 'View_Own_Document');
    const filterEmployee = getAuthPermis.filter((f) => f == 'View_Employee');
    const filterDepartment = getAuthPermis.filter((f) => f == 'View_Department');
    // View_Employee_Coded_Value
    // View_Employee_Profile
    const filterLeave = getAuthPermis.filter(
      (f) => f == 'View_Leave_Application'
    );
    const filterMedia = getAuthPermis.filter((f) => f == 'View_Media_Library');
    const filterProfile = getAuthPermis.filter((f) => f == 'View_My_Profile');
    const filterPwd = getAuthPermis.filter((f) => f == 'View_Password');
    const filterOwnPwd = getAuthPermis.filter((f) => f == 'View_Own_Password');
    const filterPermis = getAuthPermis.filter((f) => f == 'View_Permission');
    // const filterMedia = getAuthPermis.filter((f) => f == "View_Media_Library");
    const filteProject = getAuthPermis.filter((f) => f == 'View_Project');
    const filterRole = getAuthPermis.filter((f) => f == 'View_Role');
    // const filterPermiss = getAuthPermis.filter((f) => f == "View_Permission");
    // View_Time_Tracking
    const filterUseer = getAuthPermis.filter((f) => f == 'View_User');
    const filterLog = getAuthPermis.filter((f) => f == 'View_ActivityLog');
    const filterCategory = getAuthPermis.filter((f) => f == 'View_Category');
    const allView=getAuthPermis.filter((f) => f == 'View');
    const faqView=getAuthPermis.filter((f) => f == 'View_Faq');
    const manualView=getAuthPermis.filter((f) => f == 'View_Manuals');
    const remnderView=getAuthPermis.filter((f) => f == 'View_CalendarReminder');
    const viewPerformanceAppraisal = getAuthPermis.filter(
      (f) => f == 'View_Performance_Appraisal'
    );
    const viewRecruitment = getAuthPermis.filter(
      (f) => f == 'View_Recruitment'
    );
    const viewCRMList = getAuthPermis.filter((f) => f == 'View_CRM');
    const manage_incentive = getAuthPermis.filter(
      (f) => f == 'Manage_Employee_Incentives'
    );
    const manage_exchangeRage = getAuthPermis.filter(
      (f) => f == 'Manage_Exchange_Rates'
    );
    const manage_taskCategory = getAuthPermis.filter(
      (f) => f == 'Manage_Task_Categories'
    );
    const view_progresschecker = getAuthPermis.filter(
      (f) => f == 'View_Progress_Checker'
    );
    const view_progresschecker_acc = getAuthPermis.filter(
      (f) => f == 'View_Progress_Checker_Account'
    );
    const manage_department = getAuthPermis.filter(
      (f) => f == 'Manage_Department'
    );
    
    if (otList.length > 0 || otOwnList.length > 0) {
      setOTList(false);
    }

    if (payRollView.length > 0) {
      setPayroll(false);
    }

    if (checkOffDay.length > 0) {
      setOffDayWorking(false);
    }

    if (claimType.length > 0) {
      setClaimTypeCheck(false);
    }

    if (filteProject.length > 0) {
      setProjectCheck(false);
    }
    if (filterUseer.length > 0) {
      setUserCheck(true);
    }
    if (filterRole.length > 0) {
      setRoleCheck(true);
    }
    if (filterActiveTime.length > 0) {
      setActiveTimeCheck(false);
    }
    if (filterLeave.length > 0) {
      setLeaveCheck(false);
      setLeaveTypeCheck(false);
    }

    if (filterEmployee.length > 0) {
      setEmployeeeCheck(true);      
      setDesignation(false);
    }
    if(filterDepartment.length>0){
      setDepartment(true);
    }
    if (filterMedia.length > 0) {
      seMediaCheck(false);
    }
    if (filterPermis.length > 0) {
      setPermisCheck(true);
    }
    if (filterPwd.length > 0 || filterOwnPwd.length > 0) {
      setPwdCheck(false);
    }
    if (filterDashboard.length > 0) {
      setDashboardCheck(false);
    }
    if (filterProfile.length > 0) {
      setProfilleCheck(false);
    }
    if (filterAnnounc.length > 0) {
      setAnnouncCheck(true);
    }
    if(filterNoticeBoard.length>0){
      setNoticeBoardCheck(true);
    }
    if (claimsCheck.length > 0 || approveCheck.length > 0) {
      setClaimsCheck(false);
    }

    if (filterDoc.length > 0 || filterOwnDoc.length > 0) {
      setDocCheck(false);
    }
    if (filterLog.length > 0) {
      setActivityLogCheck(true);
    }
    if (viewPerformanceAppraisal.length > 0) {
      setPerformance(false);
    }
    if (viewRecruitment.length > 0) {
      setRecruitment(false);
    }
    if (viewCRMList.length > 0) {
      setViewCRM(false);
    }
    if (manage_incentive.length > 0) {
      setCheckIncentive(false);
    }
    if (manage_exchangeRage.length > 0) {
      setCheckExchangeRage(false);
    }
    if (view_progresschecker.length > 0) {
      setViewProgressChecker(false);
    }
    if (view_progresschecker_acc.length > 0) {
      setViewProgressCheckerAcc(false);
    }
    if (manage_taskCategory.length > 0) {
      setTaskCategory(false);
    }
    if(filterCategory.length>0){
      setCategory(true);
    }
    if(faqView.length>0){
      setFAQ(true);
    }
    if(manualView.length>0){
      setManual(true);
    }
    if(remnderView.length>0){
      setReminder(true);
    }
  }, [userDatas]);

  const checkAlreadyAddedHR = (title: string) => {
    var isHaved = sidebarsDataHR[0].subcontents.find(
      (x: any) => x.title == title
    );
    return isHaved ? true : false;
  };

  // const checkAlreadyAddedProject = (title: string) => {
  //   var isHaved = sidebarsDataProject[0].subcontents.find(
  //     (x: any) => x.title == title
  //   );
  //   return isHaved ? true : false;
  // };

  const checkAlreadyAddedGeneral = (url: string) => {
    var isHaved = sidebarsDataGeneral[0].subcontents.find(
      (x: any) => x.title == url
    );
    return isHaved ? true : false;
  };

  const checkAlreadyAddedAcademy = (url: string) => {
    var isHaved = sidebarsAcademy[0].subcontents.find(
      (x: any) => x.title == url
    );
    return isHaved ? true : false;
  };

  var sidebarsDataGeneral: any = [
    {
      title: 'General Setting',
      id: 1,
      subcontents: [],
    },
  ];

  var sidebarsDataHR: any = [
    {
      title: 'HR MANAGEMENT',
      id: 1,
      subcontents: [],
    },
  ];

  var sidebarsAcademy: any = [
    {
      title: 'ACADEMY',
      id: 1,
      subcontents: [],
    },
  ];

  var sidebarsDataDashboard1: any = [
    {
      title: 'Main',
      id: 1,
      subcontents: [],
    },
  ];

  // var sidebarsDataProject: any = [
  //   {
  //     title: 'PROJECT MANAGEMENT',
  //     id: 1,
  //     subcontents: [],
  //   },
  // ];

  var sidebarsSetting: any = [
    {
      title: 'SETTING',
      id: 1,
      subcontents: [],
    },
  ];

  //Dashboar check
  //if (dashboardCheck) {
    sidebarsDataDashboard1[0].subcontents.push({
      id: 11,
      link: '/dashboard',
      title: 'Admin Dashboard',
      icon: dashboard,
      activeIcon: dashboard_active,
      active: '',
    });
  //}

  //for profile
  if (profileCheck && userJson?.user?.employee_id != 0) {
    sidebarsDataDashboard1[0].subcontents.push({
      id: 12,
      link: '/profile',
      title: 'My Profile',
      icon: profile,
      activeIcon: profile_active,
      active: '',
    });
  }

  //for project
  // if (projectCheck) {
  //   sidebarsDataProject[0].subcontents.push({
  //     id: 21,
  //     link: '/projects',
  //     title: 'Projects',
  //     icon: project,
  //     activeIcon: project_active,
  //     active: '',
  //   });
  // }

  // if (projectCheck) {
  //   sidebarsDataProject[0].subcontents.push({
  //     id: 23,
  //     link: '/projects-board',
  //     title: 'Projects Board',
  //     icon: activityLog,
  //     activeIcon: activityLog_active,
  //     active: '',
  //   });
  // }

  // //for project
  // if (viewProgressChecker) {
  //   sidebarsDataProject[0].subcontents.push({
  //     id: 24,
  //     link: "/progress-checker",
  //     title: "Progress Checker",
  //     icon: checker_project,
  //     activeIcon: checker_project_active,
  //     active: '',
  //   });
  // }

  // if (viewProgressCheckerAcc) {
  //   sidebarsDataProject[0].subcontents.push({
  //     id: 25,
  //     link: "/project-checker-acc",
  //     title: "Progress Checker Acc",
  //     icon: checker_project,
  //     activeIcon: checker_project_active,
  //     active: '',
  //   });
  // }

  // if (viewCrm) {
  //   sidebarsDataProject[0].subcontents.push({
  //     id: 22,
  //     link: '',
  //     title: 'CRM',
  //     icon: crm,
  //     activeIcon: crm_active,
  //     active: '',
  //   });
  // }

  //HR check
  if (announcCheck) {
    sidebarsDataHR[0].subcontents.push({
      order: 6,
      id: 36,
      link: '/announcement',
      title: 'Announcement',
      icon: announcement,
      activeIcon: announcement_active,
      active: '',
    });
  }
  if (noticeBoardCheck) {
    sidebarsDataHR[0].subcontents.push({
      order: 6,
      id: 36,
      link: '/notice_board',
      title: 'Notice Board',
      icon: announcement,
      activeIcon: announcement_active,
      active: '',
    });
  }
  if (perfomance) {
    sidebarsDataHR[0].subcontents.push({
      order: 3,
      id: 33,
      link: '',
      title: 'Performance Appraisal',
      icon: performance,
      activeIcon: performance_active,
      active: '',
    });
  }
  if (recruitment) {
    sidebarsDataHR[0].subcontents.push({
      order: 4,
      id: 34,
      link: '',
      title: 'Recruitment',
      icon: recruitment,
      activeIcon: recruitment_active,
      active: '',
      subMenuContents: [
        {
          id: 341,
          link: '',
          title: 'Open Positions',
          label: 'Open Positions',
          active: '',
        },
        {
          id: 342,
          link: '',
          title: 'Applicants',
          label: 'Applicants',
          active: '',
        },
      ],
    });
  }

  //for password
  if (pwdCheck) {
    sidebarsDataHR[0].subcontents.push({
      order: 2,
      id: 32,
      link: '/password',
      title: 'Password',
      icon: pwd,
      activeIcon: pwd_active,
      active: '',
    });
  }
  //for doc
  if (docCheck) {
    sidebarsDataHR[0].subcontents.push({
      order: 5,
      id: 35,
      link: '/documents',
      title: 'Documents',
      icon: doc,
      activeIcon: doc_active,
      active: '',
    });
  }

  if (claimsChecked) {
    sidebarsDataHR[0].subcontents.push({
      order: 9,
      id: 39,
      link: '/claims',
      title: 'Claims',
      icon: claims,
      activeIcon: claims_active,
      active: '',
    });
  }

  //Employee check
  if (employeeeCheck) {
    if (!checkAlreadyAddedHR('Employees')) {
      sidebarsDataHR[0].subcontents.push({
        order: 1,
        id: 31,
        link: '#',
        title: 'Employees',
        icon: employee,
        activeIcon: employee_active,
        active: '',
        subMenuContents: [],
      });
    }

    var isHaveSubContent = sidebarsDataHR[0].subcontents.find(
      (x: any) => x.title == 'Employees'
    );
    if (isHaveSubContent) {
      isHaveSubContent.subMenuContents.push({
        id: 311,
        link: '/employeeList',
        title: 'Employee List',
        label: 'Employee List',
        active: '',
      });
      // isHaveSubContent.subMenuContents.push({
      //   id: 312,
      //   link: '/employeeList/equipments',
      //   title: 'Equipments',
      //   label: 'Equipments',
      //   active: '',
      // });
      // isHaveSubContent.subMenuContents.push({
      //   id: 313,
      //   link: "/employeeList/leave-allowance",
      //   title: "Leave Allowance",
      //   label: "Leave Allowance",
      //   active: "",
      // });
    }
  }


  //for employee leave
  if (leaveCheck) {
    if (!checkAlreadyAddedHR('Employees')) {
      sidebarsDataHR[0].subcontents.push({
        id: 31,
        link: '#',
        title: 'Employees',
        icon: employee,
        activeIcon: employee_active,
        active: '',
        subMenuContents: [],
      });
    }
    var isHaveSubContent = sidebarsDataHR[0].subcontents.find(
      (x: any) => x.title == 'Employees'
    );

    if (isHaveSubContent) {
      isHaveSubContent.subMenuContents.push({
        id: 319,
        link: '/employeeList/leave',
        title: 'Leave Application',
        label: 'Leave & Time-Off',
        active: '',
      });
    }
  }

  //for employee ActiveTime
  if (ActiveTimeCheck) {
    if (!checkAlreadyAddedHR('Employees')) {
      sidebarsDataHR[0].subcontents.push({
        id: 31,
        link: '#',
        title: 'Employees',
        icon: employee,
        activeIcon: employee_active,
        active: '',
        subMenuContents: [],
      });
    }
    var isHaveSubContent = sidebarsDataHR[0].subcontents.find(
      (x: any) => x.title == 'Employees'
    );
    if (isHaveSubContent) {
      isHaveSubContent.subMenuContents.push({
        id: 315,
        link: '/employeeList/active-time-report',
        title: 'Active Time Report',
        label: 'Active Time Report',
        active: '',
      });
    }
  }

  //for monitoring
  if (ActiveTimeCheck) {
    if (!checkAlreadyAddedHR('Employees')) {
      sidebarsDataHR[0].subcontents.push({
        id: 31,
        link: '#',
        title: 'Employees',
        icon: employee,
        activeIcon: employee_active,
        active: '',
        subMenuContents: [],
      });
    }
    var isHaveSubContent = sidebarsDataHR[0].subcontents.find(
      (x: any) => x.title == 'Employees'
    );

    if (isHaveSubContent) {
      isHaveSubContent.subMenuContents.push({
        id: 317,
        link: '/employeeList/monitoring',
        title: 'Monitoring',
        label: 'Monitoring',
        active: '',
      });
    }
  }

  //for attendance
  // if (ActiveTimeCheck) {
  //   if (!checkAlreadyAddedHR("Employees")) {
  //     sidebarsDataHR[0].subcontents.push({
  //       id: 31,
  //       link: "#",
  //       title: "Employees",
  //       icon: employee,
  //       activeIcon: employee_active,
  //       active: "",
  //       subMenuContents: [],
  //     });
  //   }
  //   var isHaveSubContent = sidebarsDataHR[0].subcontents.find(
  //     (x: any) => x.title == "Employees"
  //   );

  //   if (isHaveSubContent) {
  //     isHaveSubContent.subMenuContents.push({
  //       id: 318,
  //       link: "/employeeList/attendance",
  //       title: "Attendance",
  //       label: "Attendance",
  //       active: "",
  //     });
  //   }
  // }

  //for attendance
  if (ActiveTimeCheck) {
    if (!checkAlreadyAddedHR('Employees')) {
      sidebarsDataHR[0].subcontents.push({
        id: 31,
        link: '#',
        title: 'Employees',
        icon: employee,
        activeIcon: employee_active,
        active: '',
        subMenuContents: [],
      });
    }
    var isHaveSubContent = sidebarsDataHR[0].subcontents.find(
      (x: any) => x.title == 'Employees'
    );

    if (isHaveSubContent) {
      isHaveSubContent.subMenuContents.push({
        id: 318,
        link: "/employeeList/attendance",
        title: "Attendance",
        label: "Attendance",
        active: "",
      });
    }
  }

  //for OTList
  if (otList) {
    if (!checkAlreadyAddedHR('Employees')) {
      sidebarsDataHR[0].subcontents.push({
        order: 1,
        id: 33,
        link: '#',
        title: 'Employees',
        icon: employee,
        activeIcon: employee_active,
        active: '',
        subMenuContents: [],
      });
    }
    var isHaveSubContent = sidebarsDataHR[0].subcontents.find(
      (x: any) => x.title == 'Employees'
    );
    if (isHaveSubContent) {
      isHaveSubContent.subMenuContents.push({
        id: 314,
        link: '/employeeList/ot-list',
        title: 'OT List',
        label: 'OT List',
        active: '',
      });
    }
  }
  //for payroll
  if (payroll) {
    if (!checkAlreadyAddedHR('Employees')) {
      sidebarsDataHR[0].subcontents.push({
        order: 1,
        id: 33,
        link: '#',
        title: 'Employees',
        icon: employee,
        activeIcon: employee_active,
        active: '',
        subMenuContents: [],
      });
    }
    var isHaveSubContent = sidebarsDataHR[0].subcontents.find(
      (x: any) => x.title == 'Employees'
    );
    if (isHaveSubContent) {
      isHaveSubContent.subMenuContents.push({
        id: 316,
        link: '/employeeList/payroll',
        title: 'PayRoll',
        label: 'PayRoll',
        active: '',
      });
    }
  }

  //General setting check
  //for user
  if (userCheck) {
    if (!checkAlreadyAddedGeneral('Backend Users')) {
      sidebarsDataGeneral[0].subcontents.push({
        id: 41,
        link: '#',
        title: 'Backend Users',
        icon: bkuser,
        activeIcon: bkuser_active,
        active: '',
        subMenuContents: [],
      });
    }

    var isHaveSubContent = sidebarsDataGeneral[0].subcontents.find(
      (x: any) => x.title == 'Backend Users'
    );
    if (isHaveSubContent) {
      isHaveSubContent.subMenuContents.push({
        id: 411,
        link: '/users',
        title: 'Users',
        label: 'Users',
        active: '',
      });
    }
  }

  //Academy setting check
  //for list
  if (projectCheck) {
    sidebarsAcademy[0].subcontents.push({
      id: 21,
      link: courseRootPath,
      title: 'Courses',
      icon: project,
      activeIcon: project_active,
      active: '',
    });
  }

  if (projectCheck) {
    sidebarsAcademy[0].subcontents.push({
      id: 22,
      link: instructorDashboardPath,
      title: 'Instructor Dashboard',
      icon: project,
      activeIcon: project_active,
      active: '',
    });
  }

  //for role
  if (roleCheck) {
    if (!checkAlreadyAddedGeneral('Backend Users')) {
      sidebarsDataGeneral[0].subcontents.push({
        id: 41,
        link: '#',
        title: 'Backend Users',
        icon: bkuser,
        activeIcon: bkuser_active,
        active: '',
        subMenuContents: [],
      });
    }

    var isHaveSubContent = sidebarsDataGeneral[0].subcontents.find(
      (x: any) => x.title == 'Backend Users'
    );

    if (isHaveSubContent) {
      isHaveSubContent.subMenuContents.push({
        id: 412,
        link: '/users/roles',
        title: 'Roles',
        label: 'Roles',
        active: '',
      });
    }
  }
  //for permissions
  if (permisCheck) {
    if (!checkAlreadyAddedGeneral('Backend Users')) {
      sidebarsDataGeneral[0].subcontents.push({
        id: 41,
        link: '#',
        title: 'Backend Users',
        icon: bkuser,
        activeIcon: bkuser_active,
        active: '',
        subMenuContents: [],
      });
    }

    var isHaveSubContent = sidebarsDataGeneral[0].subcontents.find(
      (x: any) => x.title == 'Backend Users'
    );
    if (isHaveSubContent) {
      isHaveSubContent.subMenuContents.push({
        id: 413,
        link: '/users/permissions',
        title: 'Permissions',
        label: 'Permissions',
        active: '',
      });
    }
  }
  //for media
  if (mediaCheck) {
    sidebarsDataGeneral[0].subcontents.push({
      id: 51,
      link: '/media',
      title: 'Media Library',
      icon: media,
      activeIcon: media_active,
      active: '',
    });
  }
  if (activityLogCheck) {
    sidebarsDataGeneral[0].subcontents.push({
      id: 52,
      link: '/activity_log',
      title: 'Activity Log',
      icon: activityLog,
      activeIcon: activityLog_active,
      active: '',
    });
  }

  //add claim form to setting
  if (claimTypeCheck) {
    sidebarsSetting[0].subcontents.push({
      order: 7,
      id: 37,
      link: '/claim-type',
      title: 'Claim Type',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
  }
  if (checkIncentive) {
    sidebarsSetting[0].subcontents.push({
      order: 8,
      id: 38,
      link: '/incentive',
      title: 'Employee Incentive',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
  }
  if (checkExchangeRate) {
    sidebarsSetting[0].subcontents.push({
      order: 9,
      id: 39,
      link: '/exchange-rate',
      title: 'Exchange Rate',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
  }

  // sidebarsSetting[0].subcontents.push({
  //   order: 10,
  //   id: 40,
  //   link: "/payroll-rate",
  //   title: "PayRoll Rate",
  //   icon: claimForm,
  //   activeIcon: claimFormActive,
  //   active: "",
  // });

  if (offDayWorking) {
    sidebarsSetting[0].subcontents.push({
      order: 11,
      id: 41,
      link: '/off-day',
      title: 'Office Working & Off Days',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
  }
  if (taskCategory) {
    sidebarsSetting[0].subcontents.push({
      order: 12,
      id: 42,
      link: '/task-category',
      title: 'Task Category',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
  }

  if (taskCategory) {
    sidebarsSetting[0].subcontents.push({
      order: 13,
      id: 43,
      link: '/project-status',
      title: 'Project Status',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
  }

  if (taskCategory) {
    sidebarsSetting[0].subcontents.push({
      order: 14,
      id: 44,
      link: '/project-tech',
      title: 'Project Tech',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
  }

  if (taskCategory) {
    sidebarsSetting[0].subcontents.push({
      order: 15,
      id: 45,
      link: '/project-keydate',
      title: 'Project KeyDate',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
  }
  if (department) {
    sidebarsSetting[0].subcontents.push({
      order: 16,
      id: 46,
      link: '/departments',
      title: 'Department',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
  }
  if(category){
    sidebarsSetting[0].subcontents.push({
      order: 17,
      id: 47,
      link: '/category',
      title: 'Category',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
  }
  if(faq){
    sidebarsSetting[0].subcontents.push({
      order: 18,
      id: 48,
      link: '/faq',
      title: 'FAQ',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
    sidebarsSetting[0].subcontents.push({
      order: 19,
      id: 49,
      link: '/faq_types',
      title: 'FAQ Type',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });    
  }
  if(manual){
    sidebarsSetting[0].subcontents.push({
      order: 20,
      id: 50,
      link: '/manuals',
      title: 'Manuals',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
  }
  if(manual){
    sidebarsSetting[0].subcontents.push({
      order: 21,
      id: 50,
      link: '/usefullinks',
      title: 'Useful Link',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
  }
  if(manual){
    sidebarsSetting[0].subcontents.push({
      order: 22,
      id: 51,
      link: '/cms',
      title: 'CMS',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
  }
  sidebarsSetting[0].subcontents.push({
    order: 23,
    id: 52,
    link: '/eventtype',
    title: 'Event Type',
    icon: claimForm,
    activeIcon: claimFormActive,
    active: '',
  });
  if(reminder){
    sidebarsSetting[0].subcontents.push({
      order: 24,
      id: 53,
      link: '/event-reminder',
      title: 'Event Reminder',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
  }
  if(reminder){
    sidebarsSetting[0].subcontents.push({
      order: 24,
      id: 53,
      link: '/workgroups',
      title: 'Workgroup',
      icon: claimForm,
      activeIcon: claimFormActive,
      active: '',
    });
  }
  
  sidebarsSetting[0].subcontents.push({
    order: 25,
    id: 54,
    link: '/custom_logo',
    title: 'Mobile Logo',
    icon: claimForm,
    activeIcon: claimFormActive,
    active: '',
  });
  // sidebarsSetting[0].subcontents.push({
  //   order: 18,
  //   id: 48,
  //   link: '/language',
  //   title: 'Language',
  //   icon: language,
  //   activeIcon: languageActive,
  //   active: '',
  // });
  const sidebarsData = [
    ...sidebarsDataDashboard1,
    ...sidebarsAcademy,
    //...sidebarsDataProject,
    ...sidebarsDataHR,
    ...sidebarsDataGeneral,
    ...sidebarsSetting,
  ];

  // console.log("main", sidebarsData);

  return sidebarsData;
};

export default SidebarFunc;
