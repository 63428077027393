import React, {
  // Component,
  Dispatch,
  FC,
  SetStateAction,
  useState,
  useRef,
  useEffect,
  useCallback,
  // useCallback,
} from "react";
import { Modal, Fade, CircularProgress } from "@mui/material";
import "tinymce/skins/ui/oxide/skin.min.css";
import Styles from "../../components/common/components.module.scss";
import whiteCancel from "../common/img/white-cancel.svg";
import blackCancel from "../common/img/black-cancel.svg";
import saveIcon from "./img/submit-icon.svg";
import { useGetTagsDataCustomQuery } from "../../app/services/annoucement-sheet";
import { CommonTagDropdown } from "../common/CommonTagDropdown";
import DOMPurify from "dompurify";
import classNames from "classnames";
import { renderDateFormatYMD } from "../DashboardCalendarView/DashboardAddEventPopup";
import { TaskCategoriesList } from "../../app/services/category";
import CommonLanguage from "../common/CommonLanguage";
import { twJoin } from "tailwind-merge";
import {
  ClassicEditor,
  Context,
  InlineEditor,
  ContextWatchdog,
  Autoformat,
  Bold,
  Italic,
  BlockQuote,
  Base64UploadAdapter,
  CKFinder,
  CKFinderUploadAdapter,
  CloudServices,
  EditorConfig,
  Essentials,
  Heading,
  Image,
  ImageCaption,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  PictureEditing,
  Indent,
  Link,
  List,
  Mention,
  Paragraph,
  PasteFromOffice,
  Table,
  TableToolbar,
  TextTransformation,
} from "ckeditor5";

import { CKEditor, CKEditorContext } from "@ckeditor/ckeditor5-react";

import "ckeditor5/ckeditor5.css";
export const typeList = ["Caution", "Notice", "Warning"];

interface IModalAnnounEditor {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  valueChangeCallback: (value: any, id: string) => Promise<void>;
  totalLength: number;
  loggedUser: string;
  createTagData?: (label: string) => void;
  saving?: boolean;
  setSaving?: Dispatch<SetStateAction<boolean>>;
  departments: any;
  categories: TaskCategoriesList | undefined;
  employeeList: any;
  editRowData: any;
}

// interface IFilterStates {
//   tag: string;
// }
const ManualModalEditor: FC<IModalAnnounEditor> = ({
  isOpen,
  setIsOpen,
  valueChangeCallback,
  totalLength,
  loggedUser,
  createTagData,
  saving,
  setSaving,
  departments,
  categories,
  employeeList,
  editRowData,
}) => {
  const tags = categories?.categoryList
    // .filter((x: any) => x.flag === "anno" || x.flag === null)
    ?.map((tag: any, idx: any) => ({
      label: tag.name_en,
      value: tag.id,
    }))
    .filter((tag: any) => tag.label.toLowerCase() !== "all");

  const editorRef_en: any = useRef(null);
  const editorRef_tc: any = useRef(null);
  const editorRef_sc: any = useRef(null);
  const [goalTitle_en, setGoalTitle_en] = useState("");
  const [goalTitle_tc, setGoalTitle_tc] = useState("");
  const [goalTitle_sc, setGoalTitle_sc] = useState("");
  const [departmentError, setDepartmentError] = useState("");
  const [employeeError, setEmployeeError] = useState("");
  const [chosenTags, setChosenTags] = useState<string[]>([]);
  const [selectedCategoryData, setSelectedCategoryData] = useState<string[]>(
    []
  );
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [selectedDepartmentsData, setSelectedDepartmentsData] = useState<
    string[]
  >([]);
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [selectedEmployeesData, setSelectedEmployeesData] = useState<string[]>(
    []
  );
  const [employees, setEmployees] = useState<any>(employeeList);
  const [postedDate, setPostedDate] = useState(new Date());
  const [expiryDate, setExpiryDate] = useState<Date>();
  const [myGoal_en, setMyGoal_en] = useState("");
  const [myGoal_tc, setMyGoal_tc] = useState("");
  const [myGoal_sc, setMyGoal_sc] = useState("");
  // const [loading, setLoading] = useState(true);
  const [titleError, setTitleError] = useState("");
  const [contentError, setContentError] = useState("");
  const [filenameErr, setFilenameErr] = useState("");
  const [tagError, setTagError] = useState("");
  const [typeData, setTypeData] = useState(typeList[0]);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [uploading, setUploading] = useState<boolean>(false);
  const [selectedFileName, setSelectedFileName] = useState("No file chosen");
  const [allFile, setFile] = useState<any>({});
  const [allPrevFiles, setAllPrevFiles] = useState<any[]>([]);
  const [filename,setFilename]=useState<string>('');
  const [oldDocuments, setOldDocuments] = useState<any[]>([]);
  const [activeLang, setActiveLang] = useState<string>("en");
  const [status, setStatus] = useState<boolean>(false);
  const [id, setID] = useState<string>("");
  const uploadRef: any = useRef(null);
  const goalTitleHandler = (event: any, language: string) => {
    if (language == "en") setGoalTitle_en(event.target.value);
    if (language == "tc") setGoalTitle_tc(event.target.value);
    if (language == "sc") setGoalTitle_sc(event.target.value);
  };

  const handleEditorChange = (event: any, editor: any) => {
    if (activeLang == "en") setMyGoal_en(editor.getData());
    if (activeLang == "tc") setMyGoal_tc(editor.getData());
    if (activeLang == "sc") setMyGoal_sc(editor.getData());
  };

  const testingHandler = (event: any) => {
    if (setSaving) {
      setSaving(true);
    }
    
    if (filename=='' || filename==undefined) {
      if (setSaving) {
        setSaving(false);
      }
      setFilenameErr("Filename is required");
      return;
    } else {
      setFilenameErr("");
    }
    if (!selectedDepartments || selectedDepartments.length <= 0) {
      if (setSaving) {
        setSaving(false);
      }
      setDepartmentError("Department is required");
      return;
    } else {
      setDepartmentError("");
    }
    if (!selectedEmployees || selectedEmployees.length <= 0) {
      if (setSaving) {
        setSaving(false);
      }
      setEmployeeError("Employee is required");
      return;
    } else {
      setEmployeeError("");
    }
    if (!chosenTags || chosenTags.length <= 0) {
      if (setSaving) {
        setSaving(false);
      }
      setTagError("Category is required");
      return;
    } else {
      setTagError("");
    }
    setTitleError("");
    setFilenameErr("");
    setContentError("");
    setTagError("");
    const saveData = {
      name_en: goalTitle_en,
      name_tc: goalTitle_tc,
      name_sc: goalTitle_sc,
      description_en: myGoal_en,
      description_tc: myGoal_tc,
      description_sc: myGoal_sc,
      file: uploadRef?.current?.files[0],
      filename:filename,
      categories: selectedCategoryData?.map((x: any) => x.id).join(","),
      employee: selectedEmployeesData.find((x:any)=>x.name=='All')?'all':selectedEmployeesData?.map((x: any) => x.id).join(","),
      departments: selectedDepartmentsData?.map((x: any) => x.id).join(","),
      expired_at: expiryDate ? renderDateFormatYMD(expiryDate, "-") : "",
      is_download:status==true?1:0
    };
    let formSaveData = new FormData();
    formSaveData.append("categories", saveData.categories);
    formSaveData.append("employees", saveData.employee);
    formSaveData.append("departments", saveData.departments);
    formSaveData.append("file", saveData.file);
    formSaveData.append("file_name", saveData.filename);
    formSaveData.append("expired_at", saveData.expired_at);
    formSaveData.append("is_download", saveData.is_download.toString());
    valueChangeCallback(formSaveData, id);
  };
  const handleChangeFilename=(event:any)=>{
    setFilename(event.target.value);
  }
  const UploadFile = useCallback(() => {
    const file = uploadRef?.current.files[0];
    setFile(file);
    setSelectedFileName(file.name);
    const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB
    if (!file) {
      setErrorMsg("Please choose a file");
      return;
    }
    for (let i = 0; i < file.length; i++) {
      if (file[i].size > MAX_FILE_SIZE) {
        setErrorMsg("File size exceeds the maximum allowed (100MB).");
        return;
      } else {
        setErrorMsg("");
      }
    }
  }, [setSelectedFileName, setFile, setErrorMsg]);
  useEffect(() => {
    if (employeeList) {
      setEmployees(employeeList);
    }
    if (selectedDepartmentsData) {
      setEmployees([{id:'all',name:'All'},
        ...employeeList?.filter((emp: any) => {
          if (
            selectedDepartmentsData.find((x: any) => x.id == emp.department_id)
          ) {
            return emp;
          }
        })]
      );
    }
  }, [employeeList, selectedDepartmentsData]);
  useEffect(() => {
    setActiveLang("en");
    setSelectedFileName("");
    setFile({});
    if (isOpen === false) {
      setGoalTitle_en("");
      setGoalTitle_tc("");
      setGoalTitle_sc("");
      setChosenTags([]);
      setSelectedCategoryData([]);
      setSelectedDepartments([]);
      setSelectedDepartmentsData([]);
      setSelectedEmployees([]);
      setSelectedEmployeesData([]);
      setPostedDate(new Date());
      setExpiryDate(new Date());
      setMyGoal_en("");
      setMyGoal_tc("");
      setMyGoal_sc("");
      setActiveLang("en");
      setFilename('');
      setStatus(false);
    }
  }, [isOpen, saving]);

  const handleClick = () => {
    uploadRef.current.click();
  };
  useEffect(() => {
    if (editRowData) {
      const file = editRowData?.file_url?.split("/");
      setSelectedFileName(file ? file[file.length - 1] : "");
      setID(editRowData.id);
      setFilename(editRowData?.file_name);
      setGoalTitle_en(editRowData.name_en);
      setGoalTitle_tc(editRowData.name_tc);
      setGoalTitle_sc(editRowData.name_sc);
      setMyGoal_en(editRowData.description_en);
      setMyGoal_tc(editRowData.description_tc);
      setMyGoal_sc(editRowData.description_sc);
      setFilename(editRowData?.file_name)
      setStatus(editRowData.is_download==1?true:false);
      const filepath=editRowData?.file_path?.split('/');
      setSelectedFileName(filepath?filepath[filepath.length-1]:'')
      setChosenTags(editRowData?.categories?.map((cat: any) => cat.name_en));
      
      setSelectedCategoryData(
        editRowData?.categories?.map((cat: any) => {
          return {
            id: cat.id,
            name: cat.name_en,
          };
        })
      );
      setSelectedDepartments(
        editRowData?.departments?.map((cat: any) => cat.name_en)
      );
      setSelectedDepartmentsData(
        editRowData?.departments?.map((cat: any) => {
          return {
            id: cat.id,
            name: cat.name_en,
          };
        })
      );
      const filterEmp = [
        ...employeeList?.filter((emp: any) => {
          if (
            editRowData?.departments?.find((x: any) => x.id == emp.department_id)
          ) {
            return emp;
          }
        }),
      ];
      if(editRowData?.employees){
        if (editRowData?.employees.length == filterEmp.length) {
          setSelectedEmployees(["All"]);
          setSelectedEmployeesData(employeeList?.filter((x:any)=>x.id=='all'));
          
        } else {
          setSelectedEmployees(
            editRowData?.employees?.map((cat: any) => cat.name_en)
          );
          setSelectedEmployeesData(
            editRowData?.employees?.map((cat: any) => {
              return {
                id: cat.id,
                name: cat.name_en,
              };
            })
          );
        }
      }
      setEmployees([{ id: "all", name: "All" }, ...filterEmp]);
    }
  }, [editRowData]);
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  return (
    <Modal
      open={isOpen}
      style={{
        background: "rgb(0 0 0 / 0.3)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          // Set 'open' to false, however you would do that with your particular code.
          setIsOpen(false);
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus={true}
    >
      <Fade in={isOpen} timeout={500}>
        <div className="modalContent w-full xl:max-w-[1200px] max-w-[90%] annoucement-modalw-full px-5">
          <div className="">
            <div
              id="modal-modal-title"
              className="bg-vorpblue relative flex justify-between py-6 sm:px-10 px-5 rounded-t-xl"
            >
              <h3 className="text-18 font-primary font-semibold text-white">
                Manuals
              </h3>
              <button
                className="flex items-center justify-center"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <img src={whiteCancel} alt="close modal icon" />
              </button>
            </div>
            <div
              id="modal-modal-description"
              className="pb-9 px-5 bg-white rounded-b-xl  overflow-y-auto max-h-[70vh] h-full custom_scrollbar_popup"
            >
              {/* <CommonLanguage
                changeLanguage={changeLanguage}
                activeLang={activeLang}
              /> */}
              <div className="md:flex pt-5">
                <div className="w-full sm:px-3">
                  <div className="mb-5 last:mb-0">
                    <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                      File name
                    </label>
                    <input
                        type="text"
                        name="filename"
                        value={filename}
                        onChange={(event) => handleChangeFilename(event)}
                        placeholder="Title"
                        className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                      ></input>
                    <p
                      className={classNames(
                        filename !== "" ? "" : "hidden",
                        "mt-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      {filenameErr ? filenameErr : ""}
                    </p>
                  </div>
                  <div className="mb-5 last:mb-0">
                    <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                      Category
                    </label>

                    <CommonTagDropdown
                      isCreate={false}
                      isObject={true}
                      createData={createTagData}
                      showAll={true}
                      state={chosenTags}
                      setState={setChosenTags}
                      setSelected={setSelectedCategoryData}
                      options={tags ? tags : []}
                      className="py-3 border-goalinputborder 6xs:text-goalinputborder text-14 h-[48px]"
                    />
                    <p
                      className={classNames(
                        tagError !== "" ? "" : "hidden",
                        "mt-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      {tagError ? tagError : ""}
                    </p>
                  </div>
                  <div className="mb-5 last:mb-0">
                    <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                      Department
                    </label>

                    <CommonTagDropdown
                      placeholder="Select Department"
                      createData={createTagData}
                      showAll={true}
                      isObject={true}
                      state={selectedDepartments}
                      setState={setSelectedDepartments}
                      setSelected={setSelectedDepartmentsData}
                      options={
                        departments
                          ? departments?.map((dept: any) => {
                              return {
                                value: dept.id,
                                label: dept.name_en,
                              };
                            })
                          : []
                      }
                      className="py-3 border-goalinputborder 6xs:text-goalinputborder text-14 h-[48px]"
                    />
                    <p
                      className={classNames(
                        departmentError !== "" ? "" : "hidden",
                        "mt-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      {departmentError ? departmentError : ""}
                    </p>
                  </div>
                  <div className="mb-5 last:mb-0">
                    <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                      Employee
                    </label>

                    <CommonTagDropdown
                      placeholder="Select Employee"
                      createData={createTagData}
                      showAll={true}
                      isObject={true}
                      state={selectedEmployees}
                      setState={setSelectedEmployees}
                      setSelected={setSelectedEmployeesData}
                      options={
                        employees
                          ? employees?.map((emp: any) => {
                              return {
                                value: emp.id,
                                label: emp.name,
                              };
                            })
                          : []
                      }
                      className="py-3 border-goalinputborder 6xs:text-goalinputborder text-14 h-[48px]"
                    />
                    <p
                      className={classNames(
                        employeeError !== "" ? "" : "hidden",
                        "mt-2 font-poppins text-12 text-redPink"
                      )}
                    >
                      {employeeError ? employeeError : ""}
                    </p>
                  </div>
                  <div className="mb-5 last:mb-0">
                    <p className="text-black2 text-12 sm:text-15 font-medium font-primary mb-[0.688rem] block leading-tight">
                      Documents:
                    </p>
                    <div className=" border border-gray-300 text-gray-400">
                      <p className=" text-sm px-4 pt-3">Upload file (.pdf)</p>
                      <div className="flex items-center p-4">
                        <input
                          type="file"
                          id="upload"
                          name="file"
                          onChange={UploadFile}
                          ref={uploadRef}
                          className="hidden"
                          accept=".pdf"
                        />
                        <button
                          onClick={handleClick}
                          className={classNames(
                            allPrevFiles.length > 5 || uploading
                              ? "pointer-events-none"
                              : ""
                          )}
                        >
                          Choose file
                        </button>
                        <p className="font-primary text-10 sm:text-12 text-black2 ml-3">
                          {allFile?.name ? allFile.name : selectedFileName}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mb-0 last:mb-0 w-full">
                    <label
                      className="text-black2 font-normal body-15"
                      htmlFor=""
                    >
                      Download Status <span className="text-redPink">*</span>
                    </label>
                    <div className={`w-fit h-fit ${Styles.customSwitch}`}>
                      <label
                        htmlFor="status"
                        className="flex items-center cursor-pointer relative"
                      >
                        <input
                          type="checkbox"
                          id="status"
                          name="status"
                          value={`${status}`}
                          checked={status}
                          className="sr-only"
                          onChange={(e) => {
                            setStatus(e.target.checked);
                          }}
                        />
                        <div className="toggleBg bg-[#EFF2F5] w-10 h-6 sm:h-[30px] sm:w-12 rounded-full after:absolute after:content-[''] after:top-1 after:left-1 after:bg-white after:rounded-full  after:h-4 after:w-4  after:sm:h-[22px] after:sm:w-[22px] transition-all duration-300 after:transition-all after:duration-300"></div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 last:mb-0 flex items-center sm:px-5">
                <button
                  className={classNames(
                    saving ? "opacity-50 pointer-events-none" : "",
                    "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                  )}
                  onClick={testingHandler}
                >
                  {saving == true ? (
                    <div className="flex items-center justify-center w-full">
                      <CircularProgress
                        sx={{ color: "white" }}
                        className="w-5 h-5"
                        style={{ width: "20px", height: "20px" }}
                      />
                      <span className="ml-2">Saving</span>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <img
                        src={saveIcon}
                        alt="save icon"
                        className="mr-3"
                      ></img>
                      {editRowData?.id?'Update':'Save'}
                    </div>
                  )}
                </button>
                <button
                  className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <img
                    src={blackCancel}
                    alt="black icon"
                    className="mr-3"
                  ></img>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ManualModalEditor;
